<template>
    <div class="left-space-desktop"></div>
    <div class="page-content w-100">
        <div class="row g-0">
      <div class="col-sm-12">
        <p class="text-center page-heading">Medical Records</p>
      </div>
    </div>
        <div class="row">
            <div class="col-sm-12 usersStyle">
                <div class="row mt-3" style="margin: 5px;">
                    <div class="col-8 col-lg-10">
            <h4 class="mt-2">
              <span class="">Manage access</span>
            </h4>
          </div>
          <div class="col-4 col-lg-2">
            <router-link to="/user/records">
                <p class="closeButton mt-2"><font-awesome-icon :icon="['fas','times']" /></p>
          
            </router-link>
          </div>
                    <div class="hr-border"></div>
                </div>
                <div class="row mt-2" style="margin: 5px;"  v-if="user">
                    <div class="col-sm-12" v-for="index in userRecords" :key="index">
                        <label class="nameofpublic"><b>{{ index.firstName }} {{ index.lastName }}</b></label>
                    </div>
                </div>
                <div class="row mt-2" style="margin: 5px;" v-if="family">
                    <div class="col-sm-12" v-for="family in familyRecords" :key="family">
                        <label class="nameofpublic"> <b>{{ family.firstName }} {{ family.lastName }}</b></label>
                    </div>
                </div>
                <div class="row mt-2" style="margin: 5px;">
                    <div class="col-12">
                        <label>
                    <input type="checkbox" class="me-2" checked disabled>
                   Allow all doctors to view my records</label>
                </div>
                </div>

                <!-- <div class="row" style="margin:5px;">
        <div class="col-12">
            While availing consultation, a doctor may wish to view your
            medical history. On his/ her request, the system will send out an
            OTP to you When you share the OTP and the doctor enters it,
            he/ she can view your medical records.
        </div>
        <div class="col-12 mt-4">
            By default, the doctor will have access to your records only
            during the consultation duration or for 4 hours from the time you
            provide access, whichever is earliest. If you intend consulting a
            doctor regularly and wish to avoid the OTP process, you may
            add the doctor's name here.
        </div>
        <div class="col-12 mt-4">
            The doctor will
            then have access to your records without contacting you, till you
            remove his/her name.
        </div>
    </div>
    <div class="hr-border"></div>
    <div class="mt-3">
        <div class="row" style="display: flex; margin: 5px;">
            <div class="col-12 mt-3">
                <b>Allow access to my medical records to these healthcare
                    professional without OTP</b>
            </div>
        </div>
    </div>

    <div class="row mt-2 mb-3" style="margin: auto;" v-if="this.userId">
        <div class="col-8 col-lg-4 col-md-6">
            <span >
            <select class="form-control"   @change="resource">
                <option value="1">Select</option>
                <option v-for="userObj in uniqueDoctorsUser" :key="userObj" :value="userObj.id" >
                    <span >
                    {{ userObj?.doctor_id?.firstName }}
                    </span>
                </option>  
                </select>
            </span>
        </div>
        <div class="col-4 col-lg-8 col-md-6">
            <button type="button" class="btn btn-blue-color text-white" @click="setData()">Save</button>
        </div>
    </div>
    <div class="row mt-3" v-for="userName in manageAccessData" :key="userName" style="margin: auto;">
        <div class="col-6 col-lg-6 col-md-6" >
            <label>{{ userName?.hcp_id_otp?.firstName }}</label>

        </div>
        <div class="col-6 col-lg-6 col-md-6" >
            <img class="img-delete icon" src="@/assets/images/trash.png" @click="trashData(userName)" />
        </div>
    </div>
        <div class="row mt-2 mb-3" style="margin: auto;"  v-if="this.familyId">
        <div class="col-8 col-lg-4 col-md-6">
            <select class="form-control"   @change="resource">
                <option value="1">Select</option>
                <option v-for="(familyObj,index) in uniqueDoctorsFamily" :value="familyObj.id" :key="index"  >
                        {{ familyObj?.doctor_id?.firstName }}
                </option>  
            </select>
        </div>
        <div class="col-4 col-lg-8 col-md-6">
            <button type="button" class="btn btn-blue-color text-white" @click="setData()">Save</button>
        </div>
    </div>

    <div class="row mt-3" v-for="(familyName,index) in getManageAccessDataFamily" :key="index" style="margin: auto;">
        <div class="col-6 col-lg-6 col-md-6" >
            <label>{{ familyName?.doctor_id?.firstName }}</label>

        </div>
        <div class="col-6 col-lg-6 col-md-6" >
            <img class="img-delete icon" src="@/assets/images/trash.png" @click="trashData(familyName)" />
        </div>
    </div> -->
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import moment from 'moment';
// import Doctors from '../manage-access/doctors.vue'
export default {
    components: {
        // Doctors
    },
    data() {
        return {
            isAddNewPerson: false,
            isAddPerson: false,
            userRecords: [],
            familyRecords: [],
            uploadRecordUser:[],
            uploadRecordFamily:[],
            family:"",
            user:"",
            tabSelected: false,
            name: "1",
            uploadRecord:[],
            GetFamilyData:[],
            getManageAccessDataUser:[],
            getManageAccessDataFamily:[],
            manageAccessData:[]

             }
    },
    created: function () {
    this.recordsData();
    this.familyID = this.$route.params.id
        this.isRole = this.$route.path.split('/')[4];
    if(this.isRole==='user'){
      this.userId= this.$route.path.split('/')[3];
    }
    else{
      this.familyId = this.$route.path.split('/')[3]
    }
        this.uploadData();
        this.getManageAcess();
    },
    computed: {
    uniqueDoctorsUser() {
      // Use a Set to store unique names
      const uniqueNamesSet = new Set();
      // Filter out duplicate names and create a new array
      return this.uploadRecordUser.filter(doctor => {
        const firstName = doctor?.doctor_id?.id;
        if (!uniqueNamesSet.has(firstName) && doctor.isManageAccess === false ) {
          uniqueNamesSet.add(firstName);
          return true;
        }
        return false;
      });
    },
    uniqueDoctorsFamily() {
      // Use a Set to store unique names
      const uniqueNamesSet = new Set();
      // Filter out duplicate names and create a new array
      return this.uploadRecordFamily.filter(doctor => {
        const firstName = doctor?.doctor_id?.id;
        if (!uniqueNamesSet.has(firstName) ) {
          uniqueNamesSet.add(firstName);
          return true;
        }
        return false;
      });
    },
  },
    mounted() {
    const queryParameters = this.$route.path;

    // Split the queryParameters string using '/' as the delimiter
    const parts = queryParameters.split('/');
    const familyPart = parts.find(part => part === 'family');
    const userPart = parts.find(part=>part==="user");
    
    if (familyPart) {
      this.family=familyPart
    } 
    else if(userPart)  {
    this.user=userPart
    }
  },
    methods: {
        recordsData() {
      const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
      this.publicUserID = localStorage.getItem('publicSignUpData');
      axios
      .get(`${BASE_API_URL}/public-user/${this.publicUserID}/one`)
        .then((response) => {
          if (response?.data) {
            this.userRecords = [response.data];
          }
        })
      axios
        .get(`${BASE_API_URL}/family-member/${this.familyID}`)
        .then((response) => {
          if (response?.data) {
           this.familyRecords = response.data.filter((filteredValue) => {
                            return Number(filteredValue.id) === Number(this.familyID)
                        })

          }
        })
    },
        resource(event) {
            this.tabSelected = true
            const uploadID = event.target.value;
            const parsedUploadId= JSON.parse(uploadID)
            this.uploadRecord.map((docData)=>{
                if(docData.id ===  parsedUploadId){
                    this.GetFamilyData=docData
                }
            })
        },
        async setData() {
                    const nextConsultationDate= moment(this.GetFamilyData.nextConsultationDate).format("YYYY-MM-DD");
            const consultationDate= moment(this.GetFamilyData.consultation_date).format("YYYY-MM-DD");

            if(this.tabSelected){
                const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let formData = new FormData();
      formData.append('doctor_name', this.GetFamilyData.doctor_name);
      formData.append('file', this.GetFamilyData.upload_prescription); // Use the compressed image
      formData.append('consultation_date',consultationDate);
      formData.append('user_id', this.GetFamilyData?.user_id?.id);
      formData.append('doctor_id',this.GetFamilyData.doctor_id?.id)
      formData.append("caseSheet", this.GetFamilyData.caseSheet)
      formData.append('isConsultation', this.GetFamilyData.isConsultation);
      formData.append('nextConsultationDate',nextConsultationDate)
    //   formData.append('isManageAccess', 1);

      this.uniqueDoctorsFamily.map((data)=>{
          if(data.doctor_id.id === this.GetFamilyData.doctor_id.id){
            formData.append('isManageAccess', 1);
      }
    })
    if(this.GetFamilyData?.family_id?.id){
          formData.append('family_id', this.GetFamilyData?.family_id?.id);
      }

  axios
    .put(`${BASE_API_URL}/upload-records/${this.GetFamilyData.id}/update`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      }
    })
    .then((response) => {
      if (response?.data) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            let payload = {
                is_visited: true,
                user: this.GetFamilyData?.user_id?.id,
                family_id:this.GetFamilyData?.family_id?.id,
                hcp_id_otp:this.GetFamilyData.doctor_id?.id
            }
            axios
                .post(`${BASE_API_URL}/manage-access`, payload, {
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
                .then((response) => {
                    if (response.data) {
                       return response.data
                    }
                }) 
                
        this.uploadData();
      }
    })
    .catch(function (error) {
        return error
    });
        }
           
        },
        getManageAcess(){
            let publicUserId = localStorage.getItem('publicSignUpData')
    this.publicId = JSON.parse(publicUserId)
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/manage-access/`,)
                .then((response) => {
                    if (response.data) {
               const value = response.data.filter((data)=>{
                    return data.user?.id=== this.publicId
            })
            this.manageAccessData=value  
          
                    }
                })
        },
        trashData(data) {
            const uploadData= data;
            const nextConsultationDate= moment(uploadData.nextConsultationDate).format("YYYY-MM-DD");
            const consultationDate= moment(uploadData.consultation_date).format("YYYY-MM-DD");
            

            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL;
      let formData = new FormData();
      formData.append('doctor_name', uploadData.doctor_name);
      formData.append('file', uploadData.upload_prescription); // Use the compressed image
      formData.append('consultation_date',consultationDate);
      formData.append('user_id', uploadData?.user_id?.id);
      formData.append('doctor_id',uploadData.doctor_id?.id)
      formData.append("caseSheet", uploadData.caseSheet)
      formData.append('isConsultation', uploadData.isConsultation);
      formData.append('nextConsultationDate',nextConsultationDate)
      formData.append('isManageAccess', 0);
      if(uploadData?.family_id?.id){
          formData.append('family_id', uploadData?.family_id?.id);
      }

      axios
        .put(`${BASE_API_URL}/upload-records/${uploadData.id}/update`, formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          }
        })
        .then((response) => {
          if (response?.data) {
            this.uploadData();
          }
        })
        .catch(function (error) {
            return error
        });
           
        },
    uploadData() {
    const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
    let publicUserId = localStorage.getItem('publicSignUpData')
    this.publicId = JSON.parse(publicUserId)
    axios
        .get(`${BASE_API_URL}/upload-records`)
        .then((response) => {
            this.uploadRecord=response.data
            if (this.uploadRecord) {
                this.uploadRecord.map((filterData)=>{
                    if((filterData?.user_id?.id === this.publicId) && (filterData.isConsultation === true) && (filterData.family_id === null)){
                        this.uploadRecordUser.push(filterData)
                    }
                })
                this.uploadRecord.map((famData)=>{
                    if((famData?.user_id?.id === this.publicId) && (famData.isConsultation === true) && (famData.family_id?.id === JSON.parse(this.familyId))){
                         this.uploadRecordFamily.push(famData)
                     }
                })
            }

        })
        .catch(function (error) {
            return error
        });
},
    }
}
</script>
<style>
img.img-delete.icon {
    width: 35px;
}
.nameofpublic {
  font-size: 21px;
}

.DoctorTab {
    font-size: 20px;
    padding: 0px 15px 0px 15px;
}
.col-sm-12.usersStyle {
    margin: 0px 0px 0px 10px;
    background-color: #ffff;
    border-radius: 10px;
    padding-bottom: 15px;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 16%), 0 1px 2px 0 rgb(0 0 0 / 12%);

}

.col-sm-12.mx-auto.div-space {
    margin-bottom: 30px;
}

.left-space-desktop {
    margin-left: 20px;
}

.text-border {
    background-color: #00979e;
    height: 4px;
    margin-bottom: 20px;
}

.nav-link {
    display: block;
    padding: 0.5rem 1rem;
    color: #0d6efd;
    text-decoration: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out;
}

.nav-tabs .nav-link.active {
    color: white;
    background-color: #00979e;
    border-color: #dee2e6 #dee2e6 #fff;
}

@media screen and (max-width:991px) {
    .col-sm-12.usersStyle {
        margin: auto;
        background-color: #ffff;
        border-radius: none;
        padding-bottom: 15px;
        box-shadow: none;
    }

    .left-space-desktop {
        margin-left:0px;
    }
    .nav.nav-tabs {
        padding-top: 0px;
    }
}
</style>
